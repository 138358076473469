import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';
import { openShareWindow } from '../lib/helpers';

export default el => {

    const $el = $(el);

    const init = () => {
        $el.on('click', e => {
            e.preventDefault();
            openShareWindow($el.attr('href'));
        });
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
