import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

let isScrollingTo;

gsap.registerPlugin(ScrollToPlugin);

export const scrollTo = (value, opts = {}) => {

    if (isScrollingTo === value) {
        return;
    }

    isScrollingTo = value;

    let scrollMargin;

    try {
        scrollMargin = parseInt(window.getComputedStyle(value).getPropertyValue('scroll-margin-top') || 0, 10);
    } catch (error) {
        scrollMargin = 0;
    }

    gsap.to(window, {
        duration: 0.8,
        ease: 'Quart.easeInOut',
        ...opts,
        scrollTo: {
            y: value,
            offsetY: scrollMargin,
            autoKill: true,
            onAutoKill() {
                isScrollingTo = null;
            }
        },
        onComplete() {
            isScrollingTo = null;
        }
    });
};

export const scrollToTop = () => {
    scrollTo('#top', {
        onComplete() {
            $('#top').focus();
        }
    });
};

export const openShareWindow = href => {
    const left = Math.round((Viewport.width * 0.5) - 300);
    const top = Math.round((Viewport.height / 2) - 300);
    window.open(href, 'shareWindow', `height=600,width=600,top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no`);
};
