import gsap from 'gsap';
import $ from './core/Dom';
import Config from './core/Config';
import Viewport from './core/Viewport';
import Components from './core/Components';
import Dispatch from './core/Dispatch';
import { getScrollbarWidth } from './core/utils';
import { LOCK_BODY_SCROLL, UNLOCK_BODY_SCROLL } from './lib/events';
import { scrollToTop } from './lib/helpers';

import Search from './modules/Search';

gsap.defaults({ overwrite: 'auto' });

let lastScrollPosition;

const init = () => {

    Viewport.init();
    Components.init();
    Viewport.initTabbing();

    Config.set('scrollbarWidth', getScrollbarWidth());

    Search.init();

    const $html = $('html');
    const $body = $('body');

    // Scroll-to-top links
    $('[data-to-top-link]').on('click', e => {
        e.preventDefault();
        scrollToTop();
    });

    // Disable focus with mouse
    $html.addClass('using-mouse');
    $body.on('keydown', e => {
        if (e.keyCode === 9) {
            $html.removeClass('using-mouse');
        }
    });
    $body.on('mousedown', () => {
        $html.addClass('using-mouse');
    });

    // Scroll locking
    const onLockBodyScroll = () => {
        const { scrollbarWidth } = Config.get();
        lastScrollPosition = Viewport.scrollTop;
        $body.css('overflow', 'hidden');
        $body.css('width', `calc(100% - ${scrollbarWidth}px`);
    };

    const onUnlockBodyScroll = () => {
        $body.css('overflow', 'auto');
        $body.css('width', '');
    };

    Dispatch.on(LOCK_BODY_SCROLL, onLockBodyScroll);
    Dispatch.on(UNLOCK_BODY_SCROLL, onUnlockBodyScroll);

};

require('doc-ready')(init);
