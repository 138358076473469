import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import superagent from '../core/request';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';
import Components from '../core/Components';

export default function (el) {

    const $el = $(el);

    const $navItem = $el.find('[data-list-nav-item]');
    const $container = $el.find('[data-list-container]');
    const $navToggle = $el.find('[data-list-nav-toggle]');
    const $arrow = $el.find('[data-list-nav-arrow]');
    const navItems = $el.find('[data-list-nav-items]').get();

    let $activeList = $el.find('[data-list]');

    let xhr = null;
    let isLoading = false;

    const setActiveLink = category => {
        $el.find('[data-list-nav-item].-is-active').removeClass('-is-active');
        $el.find(`[data-list-nav-item="${category}"]`).addClass('-is-active');
    };

    const update = category => {

        setActiveLink(category);

        gsap.to($activeList.get(0), {
            autoAlpha: 0,
            duration: 0.25,
            onComplete() {
                gsap.set($activeList.get(0), { display: 'none' });
                $activeList = $el.find(`[data-list="${category}"]`);
                gsap.set($activeList.get(0), { display: 'block' });
                gsap.to($activeList.get(0), {
                    autoAlpha: 1,
                    duration: 0.25
                });
                $activeList.find('[data-animate-paused]')
                    .attr('data-animate', '')
                    .each(node => node.removeAttribute('data-animate-paused'));
                Dispatch.emit(CHECK_ANIMATE_ITEMS);
            }
        });
    };

    const fetchItems = (category, url) => {

        isLoading = true;

        if (xhr) {
            xhr.abort();
        }

        xhr = superagent.get(url);

        xhr
            .then(({
                status,
                text: html
            }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                $container.append(html);
                const newList = $el.find(`[data-list="${category}"]`).get(0);
                gsap.set(newList, { display: 'none', autoAlpha: 0 });
                update(category);
                Components.parse($container.get(0));
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                xhr = null;
                isLoading = false;
            });

    };

    const loadItems = (category, url) => {
        let $animateItems;
        if ($el.find(`[data-list="${category}"]`).length) {
            $animateItems = $activeList.find('[data-animate]');
            $animateItems.each(node => node.removeAttribute('data-animate'));
            $animateItems.attr('data-animate-paused', '');
            Dispatch.emit(CHECK_ANIMATE_ITEMS);
            update(category);

        } else {
            $animateItems = $activeList.find('[data-animate]');
            $animateItems.each(node => node.removeAttribute('data-animate'));
            $animateItems.attr('data-animate-paused', '');
            Dispatch.emit(CHECK_ANIMATE_ITEMS);
            fetchItems(category, url);
        }
    };

    const toggleNav = isVisible => {
        if (isVisible) {
            gsap.to(navItems, {
                height: 0,
                opacity: 0,
                y: '-20px',
                duration: 0.25,
                ease: 'Sine.easeOut'
            });
            $arrow.removeClass('-toggle');
        } else {
            gsap.set(navItems, {
                height: 'auto',
                opacity: 1,
                y: '0px'
            });
            gsap.from(navItems, {
                height: 0,
                opacity: 0,
                y: '-20px',
                duration: 0.25,
                ease: 'Sine.easeOut'
            });
            $arrow.addClass('-toggle');
        }
        $navToggle.data('list-nav-toggle', !isVisible);
    };

    const init = () => {
        $navItem.on('click', function (e) {
            e.preventDefault();
            loadItems($(this)
                .data('list-nav-item'), $(this)
                .attr('href'));
        });
        $navToggle.on('click', function (e) {
            e.preventDefault();
            toggleNav($(this)
                .data('list-nav-toggle'));
        });
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    const destroy = () => {
        $navItem.off('click');
        $navToggle.off('click');
    };

    return {
        init,
        destroy
    };

};
