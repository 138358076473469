import Flickity from 'flickity';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import superagent from '../core/request';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';

export default function (el, props) {

    const $el = $(el);

    const {
        url: ajaxUrl,
        pages: totalPages,
        section,
        big
    } = props;

    const $container = $el.find('[data-article-slider-container]').eq(0);
    const $buttonNext = $el.find('[data-article-slider-next]').eq(0);
    const $buttonPrev = $el.find('[data-article-slider-prev]').eq(0);

    let flkty;
    let xhr = null;
    let loadedItems = 3;
    let loadedPages = 1;
    let isLoading = false;
    let buttonPrevDisabled = true;

    let { size: currentBreakpoint } = Viewport.breakpoint;

    this.killSlideshow = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
        $buttonNext.off('click');
        $buttonPrev.off('click');
    };

    this.createSlideshow = () => {

        if (flkty) {
            this.killSlideshow();
        }

        flkty = new Flickity($container.get(0), {
            draggable: false,
            contain: true,
            cellSelector: '.list-item',
            prevNextButtons: false,
            pageDots: false,
            percentPosition: true,
            setGallerySize: true,
            cellAlign: 'left',
            wrapAround: false,
            accessibility: false
        });

        flkty.on('select', () => {
            if (buttonPrevDisabled && flkty.selectedIndex > 0) {
                $buttonPrev.get(0).removeAttribute('disabled');
                buttonPrevDisabled = false;
            } else if (!buttonPrevDisabled && flkty.selectedIndex === 0) {
                $buttonPrev.attr('disabled', '');
                buttonPrevDisabled = true;
            }
        });

        $buttonNext.on('click', () => {
            if (!isLoading && loadedItems - flkty.selectedIndex === 3 && loadedPages < totalPages) {
                this.loadItems();
            } else if (!isLoading) {
                flkty.next();
            }
        });

        $buttonPrev.on('click', () => {
            flkty.previous();
        });

    };

    this.loadItems = () => {

        if (isLoading) {
            return;
        }

        let url;

        if (ajaxUrl) {
            url = `/${ajaxUrl}/p${loadedPages + 1}`;
        } else {
            url = `/feed/${section}/p${loadedPages + 1}?big=${big ? '1' : '0'}`;
        }

        isLoading = true;

        if (xhr) {
            xhr.abort();
            xhr = null;
        }

        xhr = superagent.get(url);
        xhr
            .then(({ status, text: html }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                $container.find('.flickity-slider').append(html);
                loadedPages += 1;
                loadedItems += 3;
                flkty.reloadCells();
                flkty.next();
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                xhr = null;
                isLoading = false;
            });
    };

    this.onBreakpoint = () => {
        const { size: newBreakpoint } = Viewport.breakpoint;
        if (currentBreakpoint >= 750 && newBreakpoint < 750) {
            this.killSlideshow();
        } else if (currentBreakpoint < 750 && newBreakpoint >= 750) {
            this.createSlideshow();
        }
        currentBreakpoint = newBreakpoint;
    };

    this.onResize = () => {
        if (!flkty) {
            return;
        }
        const $items = $el.find('.list-item');
        $items.css('min-height', '0');
        flkty.resize();
        $items.css('min-height', '100%');
    };

    this.init = () => {
        Viewport.on('breakpoint', this.onBreakpoint);
        Viewport.on('resize', this.onResize);
        if (currentBreakpoint >= 750) {
            this.createSlideshow();
        }
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    this.destroy = () => {
        Viewport.off('breakpoint', this.onBreakpoint);
    };

    return {
        init: this.init,
        destroy: this.destroy
    };

};
