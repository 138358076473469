import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';
import { scrollTo } from '../lib/helpers';

export default el => {

    const $el = $(el);
    const $toggle = $el.find('[aria-expanded]');
    const $anchors = $el.find('a[href^="#"]');

    const toggleHours = () => {
        const isOpen = $toggle.attr('aria-expanded') === 'true';
        if (isOpen) {
            $toggle.attr('aria-expanded', 'false');
        } else {
            $toggle.attr('aria-expanded', 'true');
        }
    };

    const init = () => {
        $toggle.on('click', toggleHours);
        $anchors.on('click', function (e) {
            e.preventDefault();
            scrollTo($(this).attr('href'));
        });
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
