import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';

export default el => {

    const $el = $(el);
    const start = new Date().getTime();

    const poll = time => {
        if (window.ed24Chat) {
            window.ed24Chat.onMessages(t => {
                if (t.type === 'ENTRYPOINT_AVAILABILITY') {
                    if (t.data.availability === 'Online') {
                        $el.on('click', () => {
                            window.ed24Chat.publishAction({
                                type: 'OPEN_CHAT',
                                data: true
                            });
                        });
                        $el.text('Start chat');
                        $el.next().addClass('-online').text($el.data('open'));
                    } else {
                        $el.off('click');
                        $el.text('Chat offline');
                        $el.next().removeClass('-online').text($el.data('closed'));
                    }
                }
            });
        } else if ((time - start) < 8000) {
            window.requestAnimationFrame(poll);
        }
    };

    const init = () => {
        window.requestAnimationFrame(poll);
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    return {
        init
    };

};
