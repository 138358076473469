import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';

export default el => {

    const $el = $(el);

    const $button = $el.find('[data-topics-button]').eq(0);
    const list = $el.find('[data-topics-list]').get(0);
    const $arrow = $el.find('[data-topics-arrow]').eq(0);

    let isShowing = false;

    const toggleList = () => {
        if (isShowing) {
            gsap.to(list, { height: 0, duration: 0.5, ease: 'Quint.easeInOut' });
            $arrow.removeClass('-toggle');
        } else {
            gsap.set(list, { height: 'auto' });
            gsap.from(list, { height: 0, duration: 0.5, ease: 'Quint.easeInOut' });
            $arrow.addClass('-toggle');
        }

        isShowing = !isShowing;
    };

    const init = () => {
        gsap.set(list, { height: 0, display: 'block' });
        $button.on('click', e => {
            e.preventDefault();
            toggleList();
        });
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    const destroy = () => {
        $button.off('click');
    };

    return {
        init,
        destroy
    };

};
