import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import {
    SHOW_MENU,
    HIDE_MENU,
    HEADER_SHOW,
    HEADER_HIDE,
    HEADER_PAUSE,
    HEADER_PLAY,
    CHECK_ANIMATE_ITEMS
} from '../lib/events';

const OFFSET_TOP = 50;
const OFFSET_BUFFER = 10;

export default function (el) {

    const $el = $(el);

    let bar;
    let isHidden = false;
    let prevScrollTop = 0;
    let { size: currentBreakpoint } = Viewport.breakpoint;
    let isSmall = false;
    let isActive = false;

    this.setState = () => {
        const { scrollTop } = Viewport;
        if (scrollTop > OFFSET_TOP) {
            gsap.set(bar, { y: '-100%' });
        }
    };

    this.resetState = () => {
        const { scrollTop } = Viewport;
        if (scrollTop > OFFSET_TOP && isHidden && isActive) {
            gsap.set(bar, { y: '-100%' });
        }
    };

    this.play = () => {
        if (isActive) {
            return;
        }
        Dispatch.emit(HEADER_PLAY);
    };

    this.onHeaderPlay = () => {
        Viewport.on('scroll', this.onScroll);
        isActive = !isActive;
    };

    this.pause = () => {
        if (!isActive) {
            return;
        }
        Dispatch.emit(HEADER_PAUSE);
    };

    this.onHeaderPause = () => {
        Viewport.off('scroll', this.onScroll);
        isActive = !isActive;
    };

    this.show = () => {
        if (!isHidden) {
            return;
        }
        Dispatch.emit(HEADER_SHOW);
    };

    this.onHeaderShow = () => {
        isHidden = false;
        gsap.to(bar, {
            y: '0%',
            duration: 0.5,
            ease: 'Quint.easeOut'
        });
    };

    this.hide = () => {
        if (isHidden) {
            return;
        }
        Dispatch.emit(HEADER_HIDE);
    };

    this.onHeaderHide = () => {
        isHidden = true;
        gsap.to(bar, {
            y: '-100%',
            duration: 0.2,
            ease: 'Linear.easeNone'
        });
    };

    this.toggleSize = small => {
        if (small && isSmall) {
            $el.addClass('-grow');
            $el.removeClass('-small');
            isSmall = false;
        } else if (!small && !isSmall) {
            $el.addClass('-small');
            $el.removeClass('-grow');
            isSmall = true;
        }
    };

    this.onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - prevScrollTop) <= OFFSET_BUFFER) {
            return;
        }
        const isScrollingUp = scrollTop < prevScrollTop;
        if (isScrollingUp) {
            this.toggleSize(scrollTop < OFFSET_TOP);
            this.show();
        } else if (scrollTop > OFFSET_TOP) {
            this.hide();
            this.toggleSize(!isSmall);
        }

        prevScrollTop = scrollTop;
    };

    this.onBreakpoint = () => {
        const oldBreakpoint = currentBreakpoint;
        const { size: newBreakpoint } = Viewport.breakpoint;
        currentBreakpoint = newBreakpoint;
        if (oldBreakpoint < 1150 && currentBreakpoint >= 1150) {
            gsap.set(bar, { clearProps: 'y' });
            bar = $el.find('[data-menu-bar-wrap]')
                .get(0);
            this.resetState();
        } else if (oldBreakpoint >= 1150 && currentBreakpoint < 1150) {
            gsap.set(bar, { clearProps: 'y' });
            bar = $el.find('[data-menu-bar]')
                .get(0);
            this.resetState();
        }
    };

    this.onResize = () => {

    };

    this.destroy = () => {
        Dispatch.off(SHOW_MENU, this.pause);
        Dispatch.off(HIDE_MENU, this.play);
        Dispatch.off(HEADER_SHOW, this.onHeaderShow);
        Dispatch.off(HEADER_HIDE, this.onHeaderHide);
        Dispatch.off(HEADER_PAUSE, this.onHeaderPause);
        Dispatch.off(HEADER_PLAY, this.onHeaderPlay);

        Viewport.off('resize', this.onResize);
        Viewport.off('breakpoint', this.onBreakpoint);
        Viewport.off('scroll', this.onScroll);

        $el.find('a')
            .off('focusin');
    };

    this.init = () => {

        if (currentBreakpoint < 1150) {
            bar = $el.find('[data-menu-bar]').get(0);
        } else {
            bar = $el.find('[data-menu-bar-wrap]').get(0);
        }

        Dispatch.on(SHOW_MENU, this.pause);
        Dispatch.on(HIDE_MENU, this.play);
        Dispatch.on(HEADER_SHOW, this.onHeaderShow);
        Dispatch.on(HEADER_HIDE, this.onHeaderHide);
        Dispatch.on(HEADER_PAUSE, this.onHeaderPause);
        Dispatch.on(HEADER_PLAY, this.onHeaderPlay);

        Viewport.on('resize', this.onResize);
        Viewport.on('breakpoint', this.onBreakpoint);

        $el.find('a')
            .on('focusin', this.show);

        this.onResize();
        this.setState();
        this.onBreakpoint();
        this.play();

        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    return {
        init: this.init,
        destroy: this.destroy
    };

}
