import $ from '../core/Dom';

export default form => {

    const $form = $(form);

    let isSubmitting = false;

    const onSubmit = e => {
        if (isSubmitting) {
            e.preventDefault();
            return false;
        }
        isSubmitting = true;
        $form.addClass('-submitting');
        return true;
    };

    const init = () => {
        $form.on('submit', onSubmit);
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };

};
