import gsap from 'gsap';
import $ from '../core/Dom';
import Config from '../core/Config';
import Dispatch from '../core/Dispatch';
import superagent from '../core/request';
import { CHECK_ANIMATE_ITEMS, CLOSE_SEARCH_MODAL, OPEN_SEARCH_MODAL } from '../lib/events';
import Viewport from '../core/Viewport';
import Components from '../core/Components';

const $body = $('body');
const $searchForm = $('[data-search-form]').eq(0);
const $searchInput = $('[data-search-input]').eq(0);

let xhr = null;

let hasModal = false;
let $modal;
let $resultsContainer;
let $modalForm;
let $modalInput;
let modalOpen = false;
let $closeModalButton;

function performSearch(query) {

    if (xhr) {
        xhr.abort();
        xhr = null;
    }

    xhr = superagent.get('/finn').query({ q: query, m: 'modal' });

    xhr
        .then(({ status, text: html }) => {
            if (status !== 200 || !html) {
                throw new Error();
            }
            if (window.ga && typeof (window.ga) === 'function') {
                window.ga('send', 'pageview', `/finn?q=${query.split(' ').join('+')}`);
            }
            $resultsContainer.html(html);
            Components.parse($modal.get(0));
            Dispatch.emit(CHECK_ANIMATE_ITEMS);
        })
        .catch(error => {
            console.error(error);
        })
        .then(() => {
            xhr = null;
        });
}

function getFullWidth() {
    const { scrollbarWidth } = Config.get();
    return Viewport.width - scrollbarWidth;
}

function closeSearchModal() {

    if (!modalOpen) {
        return;
    }

    modalOpen = false;

    $body.css({ overflow: 'visible' });

    gsap.set($modal.get(0), {
        width: `${getFullWidth()}px`,
        overflow: 'hidden'
    });

    gsap.to($modal.get(0), {
        scale: 0.6,
        opacity: 0,
        duration: 0.75,
        onComplete() {
            $modal.get(0).scrollTo(0, 0);
            $modal.get(0).removeAttribute('style');
            $modalInput.val('');
            $resultsContainer.html('');
            Viewport.releaseTabbing();
            Dispatch.emit(CLOSE_SEARCH_MODAL);
        }
    });
}

function openSearchModal(query = '') {
    Dispatch.emit(OPEN_SEARCH_MODAL, { focus: false, query });
}

function createModal() {

    $body.append('<div class="search-modal"></div>');
    $modal = $('.search-modal').eq(0);
    $modal.append('<div class="grid-wrap"><div class="grid-item"><form class="search-modal__form" action="/finn" method="get" data-search-modal-form></form></div></div>');
    $modalForm = $modal.find('[data-search-modal-form]');
    $modalForm.append('<label for="search-modal-search" class="sr-only">Hei, hva leter du etter?</label>');
    $modalForm.append('<input id="search-modal-search" class="search-modal__search-input" type="text" name="q" autocomplete="off" placeholder="Hei, hva leter du etter?" data-search-modal-input />');
    $modalInput = $modal.find('[data-search-modal-input]');
    $modalForm.append('<button class="button -outline -white -dark-hover -normal-text" type="fubmit">Søk</button>');
    $modal.append('<div class="search-modal__results"></div>');
    $resultsContainer = $modal.find('.search-modal__results');
    $modal.append('<a href="#top" class="search-modal__button -close" aria-label="Close søk" data-search-modal-close><span class="search-modal__button-x"></span><span class="search-modal__button-x"></span></a>');

    $closeModalButton = $modal.find('[data-search-modal-close]');

    hasModal = true;

    $closeModalButton.on('click', e => {
        e.preventDefault();
        closeSearchModal();
    });

    $modalForm.on('submit', e => {
        e.preventDefault();
        performSearch($modalInput.val());
    });
}

function onOpenSearchModal(key, data) {

    if (modalOpen) {
        return;
    }

    if (!hasModal) {
        createModal();
    }

    if (data && data.query) {
        $modalInput.val(data.query);
    }

    modalOpen = true;

    const width = getFullWidth();
    const bodyWidth = $body.width();
    const padding = width - bodyWidth;

    gsap.set($closeModalButton.get(0), { right: `+=${padding}px` });

    gsap.set($modal.get(0), {
        display: 'block',
        width: `${getFullWidth()}px`,
        scale: 0.6
    });

    $modal.addClass('-is-opening');

    gsap.to($modal.get(0), {
        scale: 1,
        opacity: 1,
        duration: 0.75,
        ease: 'Quint.easeInOut',
        onComplete() {
            $body.css({ overflow: 'hidden' });
            $modal.removeClass('-is-opening');
            gsap.set($closeModalButton.get(0), { clearProps: 'all' });
            $modal.css({ width: '100%' });
            Viewport.lockTabbing($modal.get(0));
            if (data.focus) {
                $modalInput.focus();
            }
        }
    });
}

function onKeyUp(e) {
    if (e.keyCode === 27 && modalOpen) {
        closeSearchModal();
    }
}

function destroy() {
    Dispatch.off(OPEN_SEARCH_MODAL, onOpenSearchModal);
    $(window).off('keyup', onKeyUp);
}

function init() {

    Dispatch.on(OPEN_SEARCH_MODAL, onOpenSearchModal);

    $(window).on('keyup', onKeyUp);

    $searchForm.on('submit', e => {
        e.preventDefault();
        const query = $searchInput.val();
        if (!query) {
            return;
        }
        openSearchModal(query);
        performSearch(query);
        $searchInput.blur();
    });
}

export default {
    init, destroy
};
