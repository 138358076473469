import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';
import Viewport from '../core/Viewport';

export default function (el) {

    let viewportHeight = document.documentElement.clientHeight;
    let items = $('[data-animate]').get();
    let eventsActive = false;

    this.recheckForItems = () => {
        this.checkForItems();
        this.checkOnLoad();
    };

    this.checkForItems = () => {
        this.fetchItems();
        if (items.length > 0 && !eventsActive) {
            Viewport.on('resize', this.onResize);
            Viewport.on('scroll', this.onScroll);
            eventsActive = true;
        }
    };

    this.checkOnLoad = () => {
        items.forEach((item, i) => {
            gsap.set(item, { opacity: 0 });
            this.checkAndShow(item, i);
        });
    };

    this.fetchItems = () => {
        items = document.querySelectorAll('[data-animate]');
        if (items.length === 0) {
            Viewport.off('resize', this.onResize);
            Viewport.off('scroll', this.onScroll);
            eventsActive = false;
        }
    };

    this.checkShowing = item => item.getBoundingClientRect().top < (viewportHeight - 20);

    this.checkAndShow = (item, i) => {
        if (!this.checkShowing(item)) {
            return;
        }
        item.removeAttribute('data-animate');
        this.fetchItems();
        gsap.to(item, {
            delay: i / 10,
            opacity: 1,
            ease: 'Quint.easeInOut',
            duration: 1,
            onComplete() {
                el.removeAttribute('style');
            }
        });
    };

    this.onScroll = () => {
        items.forEach((item, i) => {
            this.checkAndShow(item, i);
        });
    };

    this.onResize = () => {
        viewportHeight = Viewport.height;
    };

    this.init = () => {
        Dispatch.on(CHECK_ANIMATE_ITEMS, this.recheckForItems);
        this.checkForItems();
        this.checkOnLoad();
    };

    this.destroy = () => {
        Dispatch.off(CHECK_ANIMATE_ITEMS, this.recheckForItems);
        if (eventsActive) {
            Viewport.off('resize', this.onResize);
            Viewport.off('scroll', this.onScroll);
        }
    };

    return {
        init: this.init,
        destroy: this.destroy
    };

};
