import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';

export default el => {

    const $el = $(el);
    const $button = $el.find('[data-accept]').eq(0);

    const { setCookie } = window.vrsg;

    const init = () => {
        $button.on('click', () => {
            setCookie('cookies-accepted', '1', 365);
            gsap.to(el, { autoAlpha: 0, duration: 0.5, ease: 'Quint.easeInOut' });
        });
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    const destroy = () => {
        $button.off('click');
    };

    return {
        init,
        destroy
    };

};
