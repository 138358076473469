import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS, CLOSE_SEARCH_MODAL, HIDE_MENU, OPEN_SEARCH_MODAL, SHOW_MENU } from '../lib/events';

export default function (el) {

    const $el = $(el);

    const $body = $('body');
    const $searchButton = $el.find('[data-menu-search]').eq(0);
    const $menuOpen = $('[data-menu-open]').eq(0);
    const $menuClose = $('[data-menu-close]').eq(0);
    const menu = $el.find('[data-menu]').get(0);
    const menuWrap = $el.find('[data-menu-wrap]').get(0);

    let isShowing = false;
    let { size: currentBreakpoint } = Viewport;

    this.onBreakpoint = () => {
        const { size: newBreakpoint } = Viewport.breakpoint;
        const oldBreakpoint = currentBreakpoint;
        currentBreakpoint = newBreakpoint;
        if (oldBreakpoint < 1150 && currentBreakpoint >= 1150) {
            this.teardown();
        } else if (oldBreakpoint >= 1150 && currentBreakpoint < 1150) {
            this.setup();
        }
    };

    this.hideMenu = () => {
        if (!isShowing) {
            return;
        }
        Dispatch.emit(HIDE_MENU);
        $body.css({ overflow: 'visible' });
        gsap.set(menu, {
            width: `${this.getFullWidth()}px`,
            overflow: 'hidden'
        });
        gsap.to(menu, {
            xPercent: 100,
            duration: 0.4,
            ease: 'Quint.easeInOut',
            clearProps: 'all',
            onComplete() {
                gsap.set(menu, { visibility: 'hidden' });
                $body.removeClass('-has-open-menu');
            }
        });
        Viewport.releaseTabbing();
        isShowing = false;
    };

    this.showMenu = () => {
        if (isShowing) {
            return;
        }
        Dispatch.emit(SHOW_MENU);
        $body.addClass('-has-open-menu');
        gsap.set(menu, {
            xPercent: 100,
            visibility: 'visible',
            width: `${this.getFullWidth()}px`
        });
        gsap.to(menu, {
            xPercent: 0,
            duration: 0.4,
            ease: 'Quint.easeInOut',
            onComplete() {
                $body.css({ overflow: 'hidden' });
                gsap.set(menu, { width: '100%' });
                Viewport.lockTabbing(menu);
            }
        });
        isShowing = true;
    };

    this.onKeyUp = e => {
        if (e.keyCode === 27 && isShowing) {
            this.hideMenu();
        }
    };

    this.onCloseSearchModal = () => {
        if (!isShowing) {
            return;
        }
        gsap.set(menu, { width: '100%' });
        $body.css({ overflow: 'hidden' });
        gsap.set(menuWrap, { clearProps: 'all' });
        $(window)
            .on('keyup', this.onKeyUp);
        Viewport.lockTabbing(menu);
    };

    this.onOpenSearchModal = () => {
        if (!isShowing) {
            return;
        }
        $(window)
            .off('keyup', this.onKeyUp);
        gsap.set(menuWrap, { overflow: 'hidden' });
        gsap.set(menu, { width: `${this.getFullWidth()}px` });
    };

    this.getFullWidth = () => {
        $body.css({ overflow: 'hidden' });
        const withOutScrollbar = $(menu).width();
        $body.css({ overflow: 'visible' });
        return withOutScrollbar;
    };

    this.teardown = () => {
        gsap.killTweensOf(menu);
        menu.removeAttribute('style');
        $menuOpen.off('click');
        $menuClose.off('click');
        isShowing = false;
        Dispatch.emit(HIDE_MENU);
        $(window)
            .off('keyup', this.onKeyUp);
        Viewport.releaseTabbing();
        $body.removeClass('-has-open-menu');
    };

    this.setup = () => {

        Dispatch.on(OPEN_SEARCH_MODAL, this.onOpenSearchModal);
        Dispatch.on(CLOSE_SEARCH_MODAL, this.onCloseSearchModal);

        gsap.set(menu, { xPercent: 100 });

        $menuOpen.on('click', e => {
            e.preventDefault();
            this.showMenu();
        });

        $menuClose.on('click', e => {
            e.preventDefault();
            this.hideMenu();
        });

        $(window)
            .on('keyup', this.onKeyUp);
    };

    this.init = () => {

        $searchButton.on('click', e => {
            e.preventDefault();
            Dispatch.emit(OPEN_SEARCH_MODAL, { focus: true });
        });

        Viewport.on('breakpoint', this.onBreakpoint);
        this.onBreakpoint();

        if (currentBreakpoint < 1150) {
            this.setup();
        }

        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    this.destroy = () => {
        $searchButton.off('click');
        this.teardown();
    };

    return {
        init: this.init,
        destroy: this.destroy
    };

};
