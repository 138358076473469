import gsap from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';

gsap.registerPlugin(DrawSVGPlugin);

const ICONS_QUEUE = [];

export default (el, props) => {

    const $el = $(el);
    const { value: icon } = props;
    const namespace = $el.find('svg').get(0).id.split('-').shift();

    let observer;
    let tween;

    const animateQueue = () => {

        if (!ICONS_QUEUE.length) {
            return;
        }

        // Sort the queue
        ICONS_QUEUE.sort((a, b) => parseInt(a.el.dataset.icon, 10) - parseInt(b.el.dataset.icon, 10));

        const nextTween = ICONS_QUEUE[0].tween;
        if (!nextTween.paused()) {
            return;
        }

        nextTween.play();
    };

    const onObserve = ([{ isIntersecting }]) => {

        if (!isIntersecting) {
            return;
        }

        // Add this animation to the queue
        ICONS_QUEUE.push({ el, tween });

        // Don't need the observer anymore
        observer.disconnect();
        observer = null;

        // Trigger the queue
        setTimeout(animateQueue, 0);
    };

    const createTween = () => {

        tween = gsap.timeline({ paused: true });

        tween.add(() => {
            for (let i = 0; i < ICONS_QUEUE.length; i += 1) {
                if (ICONS_QUEUE[i].el === el) {
                    ICONS_QUEUE.splice(i, 1);
                    break;
                }
            }
            setTimeout(animateQueue, 0);
        }, 0.3);

        if (icon === 'condoms') {

            const condoms = [
                $el.find(`#${namespace}-condom3`)
                    .get(0),
                $el.find(`#${namespace}-condom1`)
                    .get(0),
                $el.find(`#${namespace}-condom2`)
                    .get(0)
            ];

            tween
                .fromTo(condoms, {
                    scaleY: 0,
                    transformOrigin: 'bottom center'
                }, {
                    scaleY: 1,
                    duration: 0.3,
                    transformOrigin: 'bottom center',
                    stagger: 0.15,
                    ease: 'Back.easeOut'
                }, 0)
                .fromTo(condoms, {
                    scaleX: 1,
                    transformOrigin: 'bottom center'
                }, {
                    scaleX: 1.2,
                    transformOrigin: 'bottom center',
                    duration: 0.2,
                    stagger: 0.1,
                    repeat: 1,
                    yoyo: true,
                    ease: 'none'
                }, 0.15);

        } else if (icon === 'highfive') {

            tween
                .fromTo(`#${namespace}-highfiveleft1`, {
                    drawSVG: '100% 100%',
                    transformOrigin: 'bottom center'
                }, {
                    drawSVG: '0 100%',
                    duration: 0.2,
                    transformOrigin: 'bottom center'
                }, 0)
                .fromTo(`#${namespace}-highfiveright1`, {
                    drawSVG: '100% 100%',
                    transformOrigin: 'bottom center'
                }, {
                    drawSVG: '0 100%',
                    duration: 0.2,
                    transformOrigin: 'bottom center'
                }, 0.1)
                .fromTo(`#${namespace}-highfiveright`, {
                    drawSVG: '0',
                    transformOrigin: 'right center'
                }, {
                    drawSVG: '0 100%',
                    duration: 0.25,
                    transformOrigin: 'right center'
                }, 0.3)
                .fromTo(`#${namespace}-highfiveleft`, {
                    drawSVG: '100% 100%',
                    transformOrigin: 'left center'
                }, {
                    drawSVG: '0 100%',
                    duration: 0.25,
                    transformOrigin: 'left center'
                }, 0.3)
                .fromTo(`#${namespace}-highfivetop1`, {
                    drawSVG: '0'
                }, {
                    drawSVG: true,
                    duration: 0.1
                }, 0.5)
                .fromTo(`#${namespace}-highfivetop2`, {
                    drawSVG: '0'
                }, {
                    drawSVG: true,
                    duration: 0.1
                }, 0.5)
                .fromTo(`#${namespace}-highfivetop3`, {
                    drawSVG: '0'
                }, {
                    drawSVG: true,
                    duration: 0.1
                }, 0.5);

        } else if (icon === 'droplet') {

            tween
                .fromTo(`#${namespace}-droplet1`, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0 100%',
                    ease: 'Cubic.easeInOut',
                    duration: 0.5
                }, 0)
                .fromTo(`#${namespace}-droplet2b`, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0 100%',
                    ease: 'Cubic.easeIn',
                    duration: 0.25
                }, 0)
                .fromTo(`#${namespace}-droplet2a`, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0 100%',
                    ease: 'Cubic.easeOut',
                    duration: 0.25
                }, 0.25);

        } else if (icon === 'heart') {

            tween
                .fromTo(`#${namespace}-heart2`, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0 100%',
                    ease: 'Cubic.easeInOut',
                    duration: 0.5
                }, 0)
                .fromTo(`#${namespace}-heart1a`, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0 100%',
                    ease: 'Cubic.easeIn',
                    duration: 0.25
                }, 0)
                .fromTo(`#${namespace}-heart1a`, {
                    opacity: 0
                }, {
                    opacity: 1,
                    duration: 0.1
                }, 0)
                .fromTo(`#${namespace}-heart1b`, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0 100%',
                    ease: 'Cubic.easeOut',
                    duration: 0.25
                }, 0.25);

        } else if (icon === 'grouphug') {

            tween
                .fromTo(`#${namespace}-grouphug1a`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    ease: 'Quint.easeOut',
                    duration: 0.2
                }, 0)
                .fromTo(`#${namespace}-grouphug2a`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    ease: 'Quint.easeOut',
                    duration: 0.2
                }, 0.1)
                .fromTo(`#${namespace}-grouphug3a`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    ease: 'Quint.easeOut',
                    duration: 0.2
                }, 0.2)
                .fromTo(`#${namespace}-grouphug4a`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    ease: 'Quint.easeOut',
                    duration: 0.2
                }, 0.3)
                .fromTo(`#${namespace}-grouphug1b`, { drawSVG: '0' }, {
                    drawSVG: '0 100%',
                    ease: 'Quint.easeOut',
                    duration: 0.2
                }, 0.5)
                .fromTo(`#${namespace}-grouphug2b`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    ease: 'Quint.easeOut',
                    duration: 0.2
                }, 0.5)
                .fromTo(`#${namespace}-grouphug3b`, { drawSVG: '0' }, {
                    drawSVG: '0 100%',
                    ease: 'Quint.easeOut',
                    duration: 0.2
                }, 0.5)
                .fromTo(`#${namespace}-grouphug4b`, {
                    drawSVG: '100% 100%'
                }, {
                    drawSVG: '0 100%',
                    ease: 'Quint.easeOut',
                    duration: 0.2
                }, 0.5)
                .fromTo(`#${namespace}-grouphug4b`, {
                    opacity: 0
                }, {
                    opacity: 1,
                    duration: 0.1
                }, 0.5);

        } else if (icon === 'flaghug') {

            tween
                .fromTo(`#${namespace}-flaghug1a`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    duration: 0.2
                }, 0)
                .fromTo(`#${namespace}-flaghug2a`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    duration: 0.2
                }, 0.1)
                .fromTo(`#${namespace}-flaghug1b`, { drawSVG: '0' }, {
                    drawSVG: '0 100%',
                    duration: 0.2
                }, 0.3)
                .fromTo(`#${namespace}-flaghug2b`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    duration: 0.2
                }, 0.4)
                .fromTo(`#${namespace}-flaghug1c`, { drawSVG: '0' }, {
                    drawSVG: '0 100%',
                    duration: 0.2
                }, 0.6)
                .fromTo(`#${namespace}-flaghug2c`, { drawSVG: '0' }, {
                    drawSVG: '0 100%',
                    duration: 0.2
                }, 0.6)
                .fromTo(`#${namespace}-flaghug1d`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    duration: 0.2
                }, 0.8)
                .fromTo(`#${namespace}-flaghug2d`, { drawSVG: '100% 100%' }, {
                    drawSVG: '0 100%',
                    duration: 0.2
                }, 0.8);

        }
    };

    const destroyObserver = () => {
        if (!observer) {
            return;
        }
        observer.disconnect();
        observer = null;
    };

    const createObserver = () => {
        destroyObserver();
        observer = new IntersectionObserver(onObserve, {
            threshold: 1
        });
    };

    const init = () => {
        createTween();
        createObserver();
        observer.observe(el);
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    const destroy = () => {
        destroyObserver();
    };

    return {
        init,
        destroy
    };

};
