import $ from '../core/Dom';
import superagent from '../core/request';
import Dispatch from '../core/Dispatch';
import { CHECK_ANIMATE_ITEMS } from '../lib/events';

export default el => {

    const $el = $(el);
    const $container = $el.find('[data-page-container]');

    let $loadMore = $el.find('[data-page-load-more]');

    let xhr = null;
    let isLoading = false;

    const checkPagination = () => {
        const $lastItem = $el.find('[data-page-has-next]').last();
        if ($lastItem.data('page-has-next')) {
            $loadMore.attr('href', $lastItem.data('page-next-url'));
        } else {
            $loadMore.off('click').remove();
            $loadMore = null;
        }
    };

    const fetchItems = url => {

        isLoading = true;

        if (xhr) {
            xhr.abort();
        }

        xhr = superagent.get(url);

        xhr
            .then(({ status, text: html }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                $container.append(html);
                checkPagination();
                Dispatch.emit(CHECK_ANIMATE_ITEMS);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                xhr = null;
                isLoading = false;
            });
    };

    const init = () => {
        $loadMore.on('click', function (e) {
            e.preventDefault();
            fetchItems($(this).attr('href'));
        });
        Dispatch.emit(CHECK_ANIMATE_ITEMS);
    };

    const destroy = () => {
        if ($loadMore) {
            $loadMore.off('click');
        }
    };

    return {
        init,
        destroy
    };

};
