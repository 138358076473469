import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $el = $(el);

    const path = $el.find('[id$="loader1a"]')
        .get(0);
    const path2 = $el.find('[id$="loader2a"]')
        .get(0);

    const { setCookie } = window.vrsg;

    const nullObject = {
        val1: 333.2,
        val1a: 463.2,
        val2: 0,
        val3: 0
    };

    const doLoader = $('html')
        .hasClass('loader-can') && !$('html')
        .hasClass('loader-done');

    const updatePath = () => {
        gsap.set(path, {
            attr: {
                d: 'M' + nullObject.val1 + ' -192.8s' + -nullObject.val2 + ' 52 ' + -nullObject.val3 + ' 156V223'
            }
        });
        gsap.set(path2, {
            attr: {
                d: 'M' + nullObject.val1a + ' -192.8s' + nullObject.val2 + ' 52 ' + nullObject.val3 + ' 156V223'
            }
        });
    };

    const animate = () => {
        gsap.timeline({
            onComplete() {
                setCookie('loader-done', '1', 0);
            }
        })
            .to(nullObject, {
                delay: 0.5,
                val1: 359,
                val1a: 437.4,
                val2: 25.8,
                val3: 25.8,
                onUpdate: updatePath,
                ease: 'Sine.easeOut',
                duration: 0.35
            })
            .to(nullObject, {
                delay: 0.75,
                val1: 333.2,
                val1a: 463.2,
                val2: 0,
                val3: 0,
                duration: 0.5,
                onUpdate: updatePath
            })
            .to(el, {
                delay: 0.75,
                autoAlpha: 0,
                duration: 0.5
            });
    };

    const init = () => {
        if (!doLoader) {
            return;
        }
        gsap.set(path, {
            attr: {
                d: 'M333.2 -192.8s 0 52 0 156V223'
            }
        });
        gsap.set(path2, {
            attr: {
                d: 'M463.2 -192.8s 0 52 0 156V223'
            }
        });
        animate();
    };

    return {
        init
    };

};
